import React from "react";
import { withContext } from "~context";
import { PageLayout } from "~components/templates/page-layout";
import { CompanyInfo } from "~components/home/company";

const Component: React.FC = () => {
  return (
    <PageLayout
      paddingPattern="larger-than-header"
      meta={{
        title: "会社概要",
        description: "株式会社Flatt Securityの会社概要に関するページです。",
      }}
    >
      <CompanyInfo />
    </PageLayout>
  );
};

export default withContext(Component);
